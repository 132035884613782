import React, { useEffect, useState } from "react";
import { Folder, FolderOpen, File, RefreshCw, Upload } from "lucide-react";
import CircularProgress from "@mui/material/CircularProgress";
import { API_URL } from "../../../globals";
import { supabase } from "../../../supabaseClient";
import OnboardingModal from "../../../components/OnboardingModal";

const styles = `
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
`;

const FileExplorer = ({ studioId, changeEditor, refresh }) => {
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [data, setData] = useState({});
  const [loadingFolder, setLoadingFolder] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(null);

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  useEffect(() => {
    if (!data['.']?.contents) return;

    const contents = data['.'].contents;
    const fileCount = Object.keys(contents).length;
    const hasOnboarded = localStorage.getItem('hasOnboarded');

    if (fileCount === 1 && !hasOnboarded) {
      setShowOnboarding(true);
    }
  }, [data]);

  const fetchFolderTree = async (folderPath = "") => {
    setLoadingFolder(folderPath);
    if (!folderPath) setIsRefreshing(true);
    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const headers = { Authorization: `Bearer ${authToken}` };
    const response = await fetch(`${API_URL}/studio/folder-tree?studio_id=${studioId}&folder_path=${folderPath}`, {
      headers: headers,
    });
    const folderData = await response.json();
    setLoadingFolder(null);
    if (!folderPath) setIsRefreshing(false);
    return folderData;
  };

  useEffect(() => {
    fetchFolderTree().then(setData);
  }, [refresh, studioId]);

  const toggleFolder = async (folderPath) => {
    if (expandedFolders.includes(folderPath)) {
      setExpandedFolders(expandedFolders.filter((path) => path !== folderPath));
    } else {
      setExpandedFolders([...expandedFolders, folderPath]);
      
      // Fetch the folder contents when expanding
      const folderData = await fetchFolderTree(folderPath);
      
      // Update the data state by replacing only the contents of the clicked directory
      setData(prevData => {
        const updateFolderContents = (obj, path, newContents) => {
          const pathArray = path.split('/').filter(Boolean);
          let current = obj;

          // Navigate to the parent of the target folder
          for (let i = 0; i < pathArray.length - 1; i++) {
            if (!current[pathArray[i]] || !current[pathArray[i]].contents) {
              return obj; // Path doesn't exist, return original object
            }
            current = current[pathArray[i]].contents;
          }

          // Update the contents of the target folder
          const targetKey = pathArray[pathArray.length - 1];
          if (current[targetKey]) {
            current[targetKey] = {
              ...current[targetKey],
              contents: newContents
            };
          }

          return { ...obj };
        };
        
        return updateFolderContents(prevData, folderPath, folderData);
      });
    }
  };

  const handleFileClick = (filePath) => {
    changeEditor(filePath);
  };

  const isFile = (value) => {
    return value && value.hasOwnProperty('contents') && Object.keys(value.contents).length === 0;
  };

  const handleRefresh = async () => {
    setExpandedFolders([]); // Close all folders
    const freshData = await fetchFolderTree(); // Fetch from root
    setData(freshData);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Add the uploading file to the data state temporarily
    setUploadingFile(file.name);
    setData(prevData => ({
      ...prevData,
      '.': {
        ...prevData['.'],
        contents: {
          ...prevData['.'].contents,
          [file.name]: { contents: {} }
        }
      }
    }));

    const authToken = (await supabase.auth.getSession()).data.session.access_token;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('studio_id', studioId);

    try {
      const response = await fetch(`${API_URL}/studio/upload-file`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        body: formData
      });

      if (response.ok) {
        handleRefresh(); // Refresh the file explorer after successful upload
      } else {
        const errorData = await response.json();
        console.error('Upload failed:', errorData);
        // Remove the temporary file from the tree if upload failed
        setData(prevData => {
          const newContents = { ...prevData['.'].contents };
          delete newContents[file.name];
          return {
            ...prevData,
            '.': {
              ...prevData['.'],
              contents: newContents
            }
          };
        });
      }
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploadingFile(null);
    }
  };

  const renderTree = (node, depth = 0, parentPath = '') => {
    if (!node || typeof node !== 'object') return null;
    
    return Object.entries(node).map(([key, value]) => {
      if (!value || typeof value !== 'object') return null;
      
      const fullPath = parentPath ? `${parentPath}/${key}` : key;
      const isFileItem = isFile(value);
      const isExpanded = expandedFolders.includes(fullPath);
      const isLoading = loadingFolder === fullPath;
      const isUploading = uploadingFile === key;
      
      return (
        <div key={fullPath}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "2px 0",
              marginLeft: depth * 8,
              cursor: "pointer",
              fontSize: "0.95rem",
              animation: isUploading ? "blink 1s ease-in-out infinite" : "none",
            }}
            onClick={() => (isFileItem ? handleFileClick(fullPath) : toggleFolder(fullPath))}
          >
            {isFileItem ? (
              <>
                <File style={{ marginRight: "4px", color: "#8f8f8f", width: "1rem", flexShrink: 0 }} />
                <span style={{ 
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "150px" 
                }}>
                  {key}
                </span>
                {isUploading && (
                  <CircularProgress 
                    size={14} 
                    style={{ 
                      marginLeft: "4px", 
                      color: "#fff",
                      flexShrink: 0 
                    }} 
                  />
                )}
              </>
            ) : (
              <>
                {isExpanded ? (
                  <FolderOpen style={{ marginRight: "4px", color: "#ffca28", width: "1rem", flexShrink: 0 }} />
                ) : (
                  <Folder style={{ marginRight: "4px", color: "#ffca28", width: "1rem", flexShrink: 0 }} />
                )}
                <span style={{ 
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "150px"
                }}>
                  {key}
                </span>
                {isLoading && (
                  <CircularProgress 
                    size={14} 
                    style={{ 
                      marginLeft: "4px", 
                      color: "#fff",
                      flexShrink: 0 
                    }} 
                  />
                )}
              </>
            )}
          </div>
          {!isFileItem && isExpanded && renderTree(value.contents, depth + 1, fullPath)}
        </div>
      );
    }).filter(Boolean);
  };

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    localStorage.setItem('hasOnboarded', 'true');
  };

  return (
    <div style={{ maxHeight: "90vh", overflowY: "auto"}}>
      <OnboardingModal 
        open={showOnboarding} 
        onClose={handleOnboardingClose} 
      />
      <div 
        style={{ 
          padding: "8px", 
          display: "flex", 
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <span style={{ fontSize: "0.9rem", fontWeight: 500 }}>File Explorer</span>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <label
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              opacity: 0.7,
              padding: "4px",
              borderRadius: "4px",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.opacity = "1")}
            onMouseLeave={(e) => (e.currentTarget.style.opacity = "0.7")}
          >
            <input
              type="file"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <Upload style={{ width: "0.9rem" }} />
          </label>
          <div
            onClick={handleRefresh}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              opacity: isRefreshing ? 1 : 0.7,
              padding: "4px",
              borderRadius: "4px",
            }}
            onMouseEnter={(e) => !isRefreshing && (e.currentTarget.style.opacity = "1")}
            onMouseLeave={(e) => !isRefreshing && (e.currentTarget.style.opacity = "0.7")}
          >
            <RefreshCw 
              style={{ 
                width: "0.9rem",
                animation: isRefreshing ? "spin 1s linear infinite" : "none"
              }} 
            />
          </div>
        </div>
      </div>
      {renderTree(data['.']?.contents, 0, '.')}
    </div>
  );
};

export default FileExplorer;